import axios from "axios";

const json_headers = {
  "Content-type": "application/json",
  Accept: "application/json",
};

const http = axios.create({
  baseURL: process.env.REACT_APP_XYP_URL,
  headers: json_headers,
});

const getVehicle = (val: string) =>
  http
    .get("/vehicle?val=" + val, {
      headers: {
        "X-Api-Key": "CqnqnR6b9@AJMDAe4fu*",
        "Cache-Control": "no-cache",
      },
    })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));

const XypService = {
  getVehicle,
};

export default XypService;
