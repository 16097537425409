import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Typography from "../../base-components/Typography";
import Card from "../../components/Card";
import Input from "../../base-components/Input";
import Button from "../../base-components/Button";

import { ReactComponent as User } from "../../assets/icons/user-1.svg";

// store
import useStore2 from "../../store/hook";

export default function Victim() {
  const navigate = useNavigate();
  const { victim, setVictim } = useStore2();
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: {
      lastName: victim.lastName,
      name: victim.name,
      registryNumber: victim.registryNumber,
      mobile: victim.mobile,
      // address: victim?.address,
    },
  });

  const onSubmit = (data: any) => {
    setVictim(data);
    navigate("/xyp-vehicle-info");
  };

  return (
    <>
      <Typography variant="Title" size="md" className="w-72">
        Та хохирогч жолоочийн мэдээллийг бичиж оруулна уу /dev mode/
      </Typography>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)} className={"space-y-4"}>
          <header className="flex items-center space-x-3 mt-2">
            <div className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-secondary-12">
              <User fill="#E3A13A" className="w-4 h-4" />
            </div>
            <Typography variant="Title" size="md">
              Хохирогч жолоочийн мэдээллийг оруулна уу?
            </Typography>
          </header>
          <div className="space-y-3 mt-4">
            <Input
              label="Овог"
              className="w-full"
              {...register("lastName", {
                pattern: new RegExp(/^[А-ЯЁӨҮ-]+$/i),
                required: true,
                onChange: (e) => {
                  trigger("lastName");
                }
              })}
            />
            {errors?.lastName &&
              errors?.lastName?.type === "pattern" && (
                <p className="text-xs text-error font-semibold mt-1 ml-1">Овог буруу байна. Шалгана уу!</p>
              )}
            {errors?.lastName?.type === "required" && (
              <p className="text-xs text-error font-semibold mt-1 ml-1">
                Овог оруулна уу.
              </p>
            )}
            <Input
              label="Нэр"
              className="w-full"
              {...register("name", {
                pattern: new RegExp(/^[А-ЯЁӨҮ-]+$/i),
                required: true,
                onChange: (e) => {
                  trigger("name");
                }
              })}
            />
            {errors?.name &&
              errors?.name?.type === "pattern" && (
                <p className="text-xs text-error font-semibold mt-1 ml-1">Нэр буруу байна. Шалгана уу!</p>
              )}
            {errors?.name?.type === "required" && (
              <p className="text-xs text-error font-semibold mt-1 ml-1">
                Нэр оруулна уу.
              </p>
            )}
            <Input
              label="Регистрийн дугаар"
              className="w-full"
              {...register("registryNumber", {
                pattern: new RegExp(/^([А-ЯЁӨҮ]{2})([0-9]{8})$/),
                required: true,
                onChange: (e) => {
                  trigger("registryNumber");
                },
              })}
            />
            {errors?.registryNumber &&
                errors?.registryNumber?.type === "pattern" && (
                  <p className="text-xs text-error font-semibold mt-1 ml-1">{`Регистрийн дугаар буруу байна. Шалгана уу!`}</p>
                )
              }
            {errors?.registryNumber?.type === "required" && (
              <p className="text-xs text-error font-semibold mt-1 ml-1">
                Регистрийн дугаараа оруулна уу.
              </p>
            )}
            <Input
              label="Утас"
              className="w-full"
              {...register("mobile", {
                pattern: new RegExp(/^[6-9]{1}?[0-9]{7}$/),
                required: true,
                onChange: (e) => {
                  trigger("mobile");
                },
              })}
            />
            {errors?.mobile &&
                errors?.mobile?.type === "pattern" && (
                  <p className="text-xs text-error font-semibold mt-1 ml-1">{`Утасны дугаар буруу байна. Шалгана уу!`}</p>
                )
              }
            {errors?.mobile?.type === "required" && (
              <p className="text-xs text-error font-semibold mt-1 ml-1">
                Утасны дугаараа оруулна уу.
              </p>
            )}
            {/* <Input
              label="Гэрийн хаяг"
              className="w-full"
              // Гэрийн хаяг - зөвхөн латин үсэг, кирил үсэг, тоо, ./цэг/, ,/таслал/, -/дундуур зураас/ гэсэн
              {...register("address", {
                pattern: new RegExp(/^[а-яёөүА-ЯЁӨҮa-zA-Z0-9,.-]+$/i),
                onChange: (e) => {
                  trigger("address");
                },
              })}
            />
            {errors?.address &&
                errors?.address?.type === "pattern" && (
                  <p className="text-xs text-error font-semibold mt-1 ml-1">{`Гэрийн хаяг буруу байна. Шалгана уу!`}</p>
                )
              }
            {errors?.address?.type === "required" && (
              <p className="text-xs text-error font-semibold mt-1 ml-1">
                Гэрийн хаяг оруулна уу.
              </p>
            )} */}
          </div>
          <Button className="w-full" >Үргэлжлүүлэх</Button>
        </form>
      </Card>
    </>
  );
}
