import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";

import AccountService from "../../services/AccountService";
import Card from "../../components/Card";

import Typography from "../../base-components/Typography";
import Input from "../../base-components/Input";
import Select from "../../base-components/Select";
import Checkbox from "../../base-components/Checkbox";
import AlertComponent from "../../base-components/Alert";
import Button from "../../base-components/Button";
import ImportImage, { imageMimeType } from "../../components/ImportImage";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-left.svg";

const AccountInfo = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      bank: {},
      account: "",
      name: "",
      other_owner: false,
      contract: "",
    },
  });

  const [bankOptions, setBankOptions] = useState([]);

  useEffect(() => {
    AccountService.get().then((response) => {
      const bankList = response.banks;
      setBankOptions([...bankList]);

      const account_info = response.data;
      const found_bank = bankList.find((x) => x.id === account_info.bank);
      account_info.bank = found_bank;

      reset({ ...account_info });
    });
  }, []);

  const uploadPhoto = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Зургийн өргөтгөлтэй файл оруулна уу./png, jpg, jpeg/");
      return;
    }
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setValue("contract", result);
        }
      };
      fileReader.readAsDataURL(file);
    }
  };
  const onSubmit = (data) => {
    data.bank = data.bank.id;
    AccountService.save(data).then(() => navigate('/application-form'))
  }

  return (
    <Fragment>
      <header className="flex items-center space-x-4">
        <Arrow className="h-[18px] w-[18px]" />
        <Typography variant="Title" size="md">
          Тээврийн хэрэгслийн өмчлөгчийн дансны мэдээлэл
        </Typography>
      </header>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)} className={"space-y-4"}>
          <div className="space-y-3">
            <Controller
              control={control}
              name="bank"
              rules={{
                required: true
              }}
              render={({ field: { value, onChange } }) => (
                  <Fragment>
                    <Select
                      label="Банк"
                      options={bankOptions}
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                      }}
                    />
                    {
                      errors?.bank?.type === "required" &&
                      <p className="text-xs text-error font-semibold mt-1 ml-1">Банкаа сонгоно уу.</p>
                    }
                  </Fragment>
                )}
            />
            <div>
              <Controller
                control={control}
                name="account"
                rules={{
                  required: true
                }}
                render={({ field: { value, onChange } }) => (
                  <Fragment>
                    <Input
                      label="Дансны дугаар"
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      className="w-full"
                    />
                    {
                      errors?.account?.type === "required" &&
                      <p className="text-xs text-error font-semibold mt-1 ml-1">Дансны дугаараа оруулна уу.</p>
                    }
                  </Fragment>
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="name"
                rules={{
                  required:  true
                }}
                render={({ field: { value, onChange } }) => (
                  <Fragment>
                    <Input
                      label="Хүлээн авагчийн нэр"
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      className="w-full"
                    />
                    {
                      errors?.name?.type === "required" &&
                      <p className="text-xs text-error font-semibold mt-1 ml-1">Хүлээн авагчийн нэр оруулна уу.</p>
                    }
                  </Fragment>
                )}
              />
            </div>
          </div>
          <Controller
            control={control}
            name="other_owner"
            render={({ field: { value, onChange } }) => (
              <Fragment>
                <Checkbox
                  checked={value}
                  label="Таны тээврийн хэрэгсэл Банк, ББСБ, Байгууллагын нэр дээр байдаг эсэх"
                  onChange={(check) => {
                    onChange(check)
                  }}
                />
              </Fragment>
            )}
          />
          <AlertComponent variant="warning">
            Албан тоотоор нөхөн төлбөрийг хэн авахыг тодорхойлдог.
            Зээлийн гэрээгээр үндсэн зээлдэгчид нөхөн төлбөрийг олгохыг шийддэг.
          </AlertComponent>
          {
            watch("other_owner", false) &&
            <div className="pt-4">
              <Typography variant="Title" size="sm">
                Албан тоот, Зээлийн гэрээ
              </Typography>
              <Controller
                control={control}
                name="contract"
                render={({ field: { value } }) => (
                  <Fragment>
                    <ImportImage
                      image={value}
                      onChange={(e) => {
                        uploadPhoto(e);
                      }}
                      className="w-full h-52 mt-4"
                    />
                    {
                      errors?.contract?.type === "required" &&
                      <p className="text-xs text-error font-semibold mt-1 ml-1">Банкаа сонгоно уу.</p>
                    }
                  </Fragment>
                )}
              />
            </div>
          }
          <div className="pt-4">
            <Button
              disabled={(watch("other_owner", false) && !watch("contract", '')) || (!watch("other_owner", false) && !watch("account") && !watch("name"))}
              type="submit"
              className="w-full"
            >
              Үргэлжлүүлэх
            </Button>
          </div>
        </form>
      </Card>
    </Fragment>
  )
}

export default AccountInfo;